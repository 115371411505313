import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { firebaseConnect, isLoaded } from 'react-redux-firebase';
import { PropTypes } from 'prop-types';
import {
  Selection,
  SelectionMode,
  SelectionZone,
  SearchBox,
  Shimmer,
  IconButton,
} from 'office-ui-fabric-react/lib';
import { Text } from 'office-ui-fabric-react';
import { profileStateToParams } from '../../utils/enhacers';
import { traerEquiposJugador } from '../../utils/equipos';
import { profilePropType } from '../../utils/proptypes';
import Stack from '../Stack';

class TeamsList extends Component {
  constructor(props) {
    super(props);
    const teams = [];

    this.selection = new Selection({
      onSelectionChanged: this.onSelectionChanged,
      selectionMode: SelectionMode.single,
    });

    this.selection.setItems(teams, false);

    this.state = {
      teams,
      selectedItem: null,
      filterText: '',
      filteredTeams: [],
      loading: true,
    };
  }

  componentDidMount = () => {
    const { profile } = this.props;
    if (profile.isLoaded) {
      this.traerEquipos();
    }
  };

  componentDidUpdate = prevProps => {
    const { teams, profile } = this.props;

    const prevTeams = prevProps.teams || {};
    const currentTeams = teams || {};
    if (!prevProps.profile.isLoaded && profile.isLoaded) {
      this.traerEquipos();
    }

    if (JSON.stringify(prevTeams) !== JSON.stringify(currentTeams)) {
      const newTeams = Object.keys(currentTeams).map(key => ({
        tid: key,
        ...teams[key],
      }));
      this.selection.setItems(newTeams, false);
      this.setState({ teams: newTeams }, () => {
        this.filterTeams();
        this.onSelectionChanged();
      });
    }
  };

  verTodos = () => {
    const { profile } = this.props;
    return profile.isAdmin || profile.isViewer;
  };

  traerEquipos = async () => {
    const { profile } = this.props;
    if (this.verTodos()) {
      this.setState({ loading: false });
    } else {
      const equipos = await traerEquiposJugador(profile);

      this.setState(
        {
          loading: false,
          filtros: equipos.reduce((chain, tid) => {
            return { ...chain, [tid]: true };
          }, {}),
        },
        this.filterTeams,
      );
    }
  };

  onSelectionChanged = () => {
    const { onTeamSelected } = this.props;
    const { selectedItem } = this.state;
    const [selected] = this.selection.getSelectedIndices();

    const newSelectedItem = this.selection.getItems()[selected] || selectedItem;
    this.setState({
      selectedItem: newSelectedItem,
    });

    onTeamSelected(newSelectedItem);

    this.forceUpdate();
  };

  unselect = () => {
    const { onTeamSelected } = this.props;
    onTeamSelected(null);
    this.setState({
      selectedItem: null,
    });
  };

  onFilterChanged = text => {
    this.setState(
      {
        filterText: text,
      },
      this.filterTeams,
    );
  };

  onRenderCell = (item, index) => {
    let isSelected = false;

    const { selectedItem } = this.state;

    if (this.selection && selectedItem) {
      isSelected = selectedItem.tid === item.tid;
    }
    return (
      <div
        className={`team ${isSelected && 'selected'}`}
        data-is-focusable
        data-selection-index={index}
        key={JSON.stringify(item)}>
        <Stack middle between>
          <Text variant="mediumPlus">
            {item.name.substring(0, 20).toUpperCase()}
          </Text>
          <div
            style={{
              backgroundColor: this.colorCuadro(item),
              width: 20,
              height: 20,
              borderRadius: 10,
            }}
          />
          {isSelected && (
            <IconButton
              iconProps={{ iconName: 'ChromeClose' }}
              onClick={this.unselect}
            />
          )}
        </Stack>
      </div>
    );
  };

  colorCuadro = ({ enRevision, aprobado, rechazado }) => {
    let color = enRevision ? 'gray' : '#0000';
    color = aprobado ? 'green' : color;
    color = rechazado ? 'red' : color;
    return color;
  };

  filterTeams = () => {
    const { teams, filterText, filtros } = this.state;
    let items = filterText
      ? teams.filter(
          item =>
            item.name.toLowerCase().indexOf(filterText.toLowerCase()) >= 0,
        )
      : teams;

    items = filtros
      ? items.filter(item => {
          return filtros[item.tid];
        })
      : items;
    this.selection.setItems(items, false);
    this.setState({ filteredTeams: items || [] }, () => {
      const { match } = this.props;
      const { selectedFromMatch } = this.state;

      if (match.params.teamId && !selectedFromMatch) {
        const index = items.findIndex(item => item.tid === match.params.teamId);
        this.selection.selectToIndex(index);
        this.setState({ selectedFromMatch: true });
      }
      if (items.length === 1) {
        this.selection.selectToIndex(0);
      }
    });
  };

  render() {
    const { filteredTeams, filterText, loading } = this.state;
    const { teams: teamsFromProps } = this.props;

    return (
      <div className="teamsList">
        {this.verTodos() && (
          <SearchBox
            value={filterText}
            placeholder="Search"
            onChange={this.onFilterChanged}
            onSearch={this.onFilterChanged}
          />
        )}

        <div className="list">
          {loading || !isLoaded(teamsFromProps) ? <Shimmer /> : null}
          <SelectionZone selection={this.selection}>
            {!loading && filteredTeams.map(this.onRenderCell)}
          </SelectionZone>
        </div>
      </div>
    );
  }
}

TeamsList.propTypes = {
  onTeamSelected: PropTypes.func.isRequired,
  teams: PropTypes.shape({}),
  profile: profilePropType.isRequired,
  match: PropTypes.shape({}).isRequired,
};

TeamsList.defaultProps = {
  teams: undefined,
};

export default compose(
  firebaseConnect(() => [
    { path: 'teams', queryParams: ['orderByChild=name'] },
  ]),
  connect(profileStateToParams),
)(TeamsList);
