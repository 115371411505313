import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import {
  Shimmer,
  ShimmeredDetailsList,
  MarqueeSelection,
  ProgressIndicator,
  Modal,
  DefaultButton,
  PrimaryButton,
  DetailsListLayoutMode,
  Selection,
  TextField,
  MaskedTextField,
  DialogFooter,
  DatePicker,
  DayOfWeek,
  Text,
  Checkbox,
  ComboBox,
  Label,
  Link,
  Dialog,
} from 'office-ui-fabric-react/lib';
import { CSVLink } from 'react-csv';
import { subYears } from 'date-fns';
import CommandHeader from '../CommandHeader';
import Stack from '../Stack';
import {
  traerJugadores,
  editarJugador,
  hacerCambio,
  updateTeam,
  deleteTeam,
} from '../../utils/equipos';
import validator from '../../utils/validator';
import jugadoresForm, { validarUsuario, modelUser } from './Forms/Jugadores';
import TeamForm from '../TeamForm';
import { profilePropType } from '../../utils/proptypes';
import { profileOnly } from '../../utils/enhacers';
import { formatShortDate, formatDate } from '../../utils/dates';
import { subirFiles } from '../../utils/files';
import {
  LIMITE_JUGADORES,
  CAMBIOS_ETAPA_2,
  LIMITAR_CAMBIOS,
  BLOQUEAR_CAMBIOS,
} from '../../config.json';

const JUGADORES_PARA_REVISION = 6;

const INITIAL_OPTIONS = [
  { key: 'all', text: 'Todos los jugadores' },
  { key: 'activo', text: 'Activos' },
  { key: 'inactivo', text: 'Inactivos' },
];
class Players extends Component {
  constructor(props) {
    super(props);
    const {
      team: { cambios = 0 },
    } = props;
    this.selection = new Selection({
      onSelectionChanged: this.onSelectionChanged,
    });
    this.columns = jugadoresForm(this);
    this.state = {
      selectionCount: null,
      showModal: false,
      showModalTeam: false,
      items: [],
      currentUser: { ...modelUser },
      currentError: {},
      loading: false,
      puedeActivar: true,
      itemsLoaded: false,
      filtro: 'all',
      puedeCambiar: false,
      cambios,
    };
  }

  componentDidMount = () => {
    this.traerJugadores();
  };

  componentDidUpdate = prevProps => {
    const { team } = this.props;
    if (JSON.stringify(prevProps.team) !== JSON.stringify(team)) {
      this.traerJugadores();
    }
  };

  traerJugadores = async () => {
    const { team } = this.props;
    const jugadores = await traerJugadores(team);
    const puedeCambiar = this.puedeCambiar();
    this.setState({
      items: jugadores,
      itemsLoaded: true,
      loading: false,
      puedeCambiar,
    });
  };

  puedeCambiar = () => {
    const { profile, team } = this.props;
    const { cambios } = this.state;
    const puedeCambiar =
      (!LIMITAR_CAMBIOS || cambios < CAMBIOS_ETAPA_2) &&
      !team.enRevision &&
      !team.aprobado;

    return (
      (profile.isAdmin || puedeCambiar) &&
      !profile.isViewer &&
      !BLOQUEAR_CAMBIOS
    );
  };

  activar = (activar = true) => {
    this.setState({
      items: this.itemsActivos(activar, true),
    });
    this.selection.setAllSelected(false);
  };

  itemsActivos = (activar, cambiar = false) => {
    const { team } = this.props;
    const { items } = this.state;
    return items.map(item => {
      if (this.selection.isKeySelected(item.identification)) {
        const jugador = {
          ...item,
          activo: item.capitan || activar,
        };
        if (cambiar) {
          editarJugador(jugador, team);
        }
        return jugador;
      }
      return item;
    });
  };

  puedeActivar = () => {
    const all = this.selection.isAllSelected();
    const jugadores = this.getActivos();
    if (all) {
      return jugadores.inscritos <= LIMITE_JUGADORES;
    }
    const nuevosActivos = this.itemsActivos(true).filter(item => item.activo);
    return nuevosActivos.length <= LIMITE_JUGADORES;
  };

  onSelectionChanged = () => {
    const count = this.selection.getSelectedCount();
    this.setState({
      selectionCount: count > 0 && count,
      puedeActivar: this.puedeActivar(),
    });
  };

  onItemInvoked = value => {
    this.selection.setAllSelected(false);
    const { items } = this.state;
    const currentUser = items.find(
      item => item.identification === value.identification,
    );
    this.setState({
      currentUser: {
        ...currentUser,
        oldId: currentUser.identification,
        birthday: new Date(currentUser.birthday),
        nuevo: false,
      },
      currentError: {},
      showModal: true,
    });
  };

  onChange = event => {
    const { currentUser } = this.state;
    const { value, id } = event.target;
    currentUser[id] = value;
    this.setState({ currentUser });
  };

  guardarCurrent = async () => {
    const { team, profile } = this.props;
    const { currentUser, cambios } = this.state;

    let currentError = await validarUsuario(
      currentUser,
      currentUser.nuevo,
      team.tid,
    );

    if (!profile.isAdmin) {
      const sinFiles =
        this.upload1.files.length === 0 &&
        this.upload2.files.length === 0 &&
        !currentUser.upload1 &&
        !currentUser.upload2;
      if (sinFiles) {
        currentError = {
          ...currentError,
          upload1: 'Debes subir al menos 1 archivo',
        };
      }
    }

    if (Object.keys(currentError).length > 0) {
      this.setState({ currentError });
      return;
    }
    this.setState({ showModal: false, loading: true });
    const resultUpload = await subirFiles(
      this.upload1,
      this.upload2,
      currentUser.upload1,
      currentUser.upload2,
    );

    await editarJugador({ ...currentUser, ...resultUpload }, team);
    if (currentUser.nuevo) {
      await hacerCambio(team, cambios);
      const newCambios = cambios + 1;

      this.setState({ cambios: newCambios }, () => {
        const puedeCambiar = this.puedeCambiar();
        this.setState({ puedeCambiar });
      });
    }
    this.traerJugadores();
  };

  renderModal = () => {
    const {
      showModal,
      currentUser,
      currentError,
      items,
      puedeCambiar,
    } = this.state;
    const {
      profile: { isAdmin },
    } = this.props;

    const capitan = items.find(item => item.capitan);

    return (
      <Modal isOpen={showModal} onDismiss={this.closeModal}>
        <div className="modalContent">
          <Text variant="xLarge">
            {currentUser.nuevo ? 'Agregar Jugador' : 'Editar Jugador'}
          </Text>
          <Stack between className="formModal">
            <div>
              <TextField
                label="Cédula"
                required
                id="identification"
                value={currentUser.identification}
                errorMessage={currentError.identification}
                disabled={!currentUser.nuevo && !isAdmin}
                onChange={value => {
                  this.onChange({
                    ...value,
                    target: {
                      ...value.target,
                      value: validator.toDigits(value.target.value),
                      id: value.target.id,
                    },
                  });
                }}
                type="number"
              />
              <TextField
                label="Primer y segundo nombre"
                required
                onChange={value => {
                  this.onChange({
                    ...value,
                    target: {
                      ...value.target,
                      value: value.target.value.toUpperCase(),
                      id: value.target.id,
                    },
                  });
                }}
                id="name"
                value={currentUser.name.toUpperCase()}
                errorMessage={currentError.name}
              />
              <TextField
                label="Primer y segundo apellido"
                required
                onChange={value => {
                  this.onChange({
                    ...value,
                    target: {
                      ...value.target,
                      value: value.target.value.toUpperCase(),
                      id: value.target.id,
                    },
                  });
                }}
                id="lastName"
                value={currentUser.lastName.toUpperCase()}
                errorMessage={currentError.lastName}
              />
              <MaskedTextField
                label="Celular"
                required
                mask="(999) 999-9999"
                onChange={value => {
                  this.onChange({
                    ...value,
                    target: {
                      ...value.target,
                      value: validator.toDigits(value.target.value),
                      id: value.target.id,
                    },
                  });
                }}
                id="tel"
                type="tel"
                value={currentUser.tel}
                errorMessage={currentError.tel}
              />
              <br />
              {isAdmin && (
                <Checkbox
                  label="Capitan"
                  isRequired
                  onChange={value =>
                    this.onChange({
                      target: { value: value.target.checked, id: 'capitan' },
                    })
                  }
                  boxSide="end"
                  checked={currentUser.capitan || false}
                  errorMessage={currentError.capitan}
                  disabled={
                    capitan &&
                    capitan.identification !== currentUser.identification
                  }
                />
              )}
            </div>
            <div>
              <TextField
                label="e-mail"
                required
                id="email"
                onChange={value => {
                  this.onChange({
                    ...value,
                    target: {
                      ...value.target,
                      value: value.target.value.toLowerCase(),
                      id: value.target.id,
                    },
                  });
                }}
                value={currentUser.email.toLowerCase()}
                errorMessage={currentError.email}
                type="email"
              />
              <DatePicker
                label="Fecha de nacimiento"
                isRequired
                firstDayOfWeek={DayOfWeek.Sunday}
                ariaLabel="Selecciona una fecha"
                maxDate={subYears(new Date(), 6)}
                onSelectDate={value =>
                  this.onChange({ target: { value, id: 'birthday' } })
                }
                id="birthday"
                value={
                  typeof currentUser.birthday === 'string'
                    ? new Date(currentUser.birthday)
                    : currentUser.birthday
                }
                errorMessage={currentError.birthday}
              />
              <br />

              <Label required htmlFor="upload1">
                Adjuntar Cédula y EPS.
                <br />
                Tipo de archivo permitido (JPG, JPEG, PNG o PDF)
              </Label>
              <b>{currentError.upload1}</b>

              <p>
                <input
                  id="upload1"
                  type="file"
                  ref={ref => {
                    this.upload1 = ref;
                  }}
                  accept="image/png, image/jpg, .png, .jpg, .pdf"
                />
                {currentUser.upload1 && (
                  <Link href={currentUser.upload1} target="_blank">
                    Ver Archivo 1
                  </Link>
                )}
              </p>
              <p>
                <input
                  id="upload2"
                  type="file"
                  ref={ref => {
                    this.upload2 = ref;
                  }}
                  accept="image/png, image/jpg, .png, .jpg, .pdf"
                />
                {currentUser.upload2 && (
                  <Link href={currentUser.upload2} target="_blank">
                    Ver Archivo 2
                  </Link>
                )}
              </p>
            </div>
          </Stack>

          <DialogFooter>
            <PrimaryButton
              disabled={!puedeCambiar}
              onClick={this.guardarCurrent}
              text={currentUser.nuevo ? 'Guardar' : 'Modificar'}
            />
            <DefaultButton onClick={this.closeModal} text="Cancelar" />
          </DialogFooter>
        </div>
      </Modal>
    );
  };

  renderModalTeam = () => {
    const { showModalTeam } = this.state;
    const { team } = this.props;
    return (
      <TeamForm
        showModal={showModalTeam}
        onHideModal={this.closeModalTeam}
        team={team}
      />
    );
  };

  renderRevisionModal = () => {
    const { showRevisionModal } = this.state;
    return (
      <Modal isOpen={showRevisionModal} onDismiss={null}>
        <div className="modalContent">
          <Stack middle between>
            <Text>
              Al enviar a revisión estás aceptando los&nbsp;
              <Link
                target="_blank"
                href="https://torneosoccercup.com/wp-content/uploads/2019/05/Revisión-juridica-TORNEO-DE-FÚTBOL-5-TYC-BetPlay-PagaTodo-2019.pdf">
                términos y condiciones
              </Link>
            </Text>
          </Stack>

          <DialogFooter>
            <PrimaryButton
              onClick={this.enviarARevisionConfirmado}
              text="Enviar a revisión"
            />
            <DefaultButton
              onClick={() => {
                this.setState({ showRevisionModal: false });
              }}
              text="Cancelar"
            />
          </DialogFooter>
        </div>
      </Modal>
    );
  };

  enviarARevisionConfirmado = async () => {
    const { team } = this.props;

    this.setState({ loading: true, showRevisionModal: false });
    await updateTeam({
      ...team,
      enRevision: true,
      rechazado: false,
      revisionTime: new Date(),
    });
    this.setState({ loading: false });
  };

  enviarARevision = () => {
    this.setState({ showRevisionModal: true });
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };

  agregarJugador = () => {
    this.setState({
      currentUser: { ...modelUser, nuevo: true },
      currentError: {},
      showModal: true,
    });
  };

  closeModalTeam = () => {
    this.setState({ showModalTeam: false });
  };

  cambiarNombreEquipo = () => {
    this.setState({ showModalTeam: true });
  };

  getActivos = () => {
    const { items } = this.state;
    if (!items) return {};

    const inactivos = items.filter(item => !item.activo).length;
    return {
      inscritos: items.length,
      inactivos,
      activos: items.length - inactivos,
    };
  };

  getCompletos = () => {
    const { items } = this.state;
    if (!items) return 0;

    return items.filter(item => item.activo && (item.upload1 || item.upload2))
      .length;
  };

  eliminarEquipo = () => {
    this.setState({ showEliminarEquipo: true });
  };

  eliminarEquipoConfirmado = async () => {
    const { team } = this.props;
    await deleteTeam(team.tid);
    this.setState({ showEliminarEquipo: false });
    window.location.reload();
  };

  renderEliminarEquipo = () => {
    const { showEliminarEquipo } = this.state;
    return (
      <Dialog
        hidden={!showEliminarEquipo}
        onDismiss={() => {
          this.setState({ showEliminarEquipo: false });
        }}>
        <div className="modalContent">
          <Stack middle between>
            <Text>
              Estás a punto de eliminar un equipo, estos datos no podrán ser
              recuperados ¿Estás seguro de eliminar definitivamente este equipo?
            </Text>
          </Stack>

          <DialogFooter>
            <PrimaryButton
              onClick={this.eliminarEquipoConfirmado}
              text="Eliminar"
            />
            <DefaultButton
              onClick={() => {
                this.setState({ showEliminarEquipo: false });
              }}
              text="Cancelar"
            />
          </DialogFooter>
        </div>
      </Dialog>
    );
  };

  renderCommandHeader = () => {
    const { profile, team } = this.props;
    const { enRevision, aprobado } = team;

    let texto = '';
    if (!enRevision) {
      texto = `- Puedes enviar a revisión cuando tengas mínimo ${JUGADORES_PARA_REVISION} jugadores completos, con cédula y EPS`;
    } else {
      texto =
        '- Equipo en revisión - No podrás hacer modificaciones mientras tanto';
    }

    if (aprobado) {
      texto = '';
    }

    const { itemsLoaded, puedeCambiar } = this.state;
    const jugadores = this.getActivos();

    return (
      <CommandHeader title="Acciones Principales">
        {profile.isAdmin ? (
          <DefaultButton onClick={this.cambiarNombreEquipo}>
            Editar nombre del equipo
          </DefaultButton>
        ) : (
          <Text>
            {team.name} {texto}
          </Text>
        )}
        <div>
          {puedeCambiar && (
            <PrimaryButton
              onClick={this.agregarJugador}
              disabled={!itemsLoaded || jugadores.activos >= LIMITE_JUGADORES}>
              Agregar Jugador
            </PrimaryButton>
          )}
          &nbsp;
          {profile.isAdmin && (
            <DefaultButton onClick={this.eliminarEquipo}>
              Eliminar Equipo
            </DefaultButton>
          )}
          {LIMITAR_CAMBIOS && (
            <>
              <br />
              <p className="updateMessage">
                Recuerda que el botón de&nbsp;
                <b>
                  <i>“Agregar jugador”</i>
                </b>
                &nbsp; estará deshabilitado para realizar cambios todos los
                fines de semana desde el Viernes 6:00pm al Lunes a las 12:00pm
              </p>
            </>
          )}
        </div>
      </CommandHeader>
    );
  };

  renderContent = () => {
    const { items, loading, itemsLoaded } = this.state;
    return (
      <>
        <div className="teams">
          {this.renderTopContent()}
          {itemsLoaded && items.length === 0 ? (
            <div className="dialogBox">
              <Text variant="xxLarge">Lista sin jugadores</Text>
            </div>
          ) : (
            this.renderTable()
          )}
        </div>
        {loading && <ProgressIndicator />}
      </>
    );
  };

  renderFilter = () => {
    const { profile, team } = this.props;

    const { items, filtro } = this.state;
    const itemsFiltered =
      filtro === 'all'
        ? items
        : items.filter(item => {
            if (item.activo && filtro === 'activo') {
              return true;
            }
            if (!item.activo && filtro === 'inactivo') {
              return true;
            }
            return false;
          });

    const keys = Object.keys(modelUser);

    const csvData = itemsFiltered.reduce(
      (chain, value) => {
        const values = keys.map(key => {
          if (key === 'updatedOn') {
            return value[key] ? formatDate(value[key]) : '';
          }

          return key === 'birthday' ? formatShortDate(value[key]) : value[key];
        });
        return [...chain, values];
      },
      [keys],
    );
    return (
      profile.isAdmin && (
        <>
          <Stack between>
            <div />
            <Stack middle end>
              <ComboBox
                defaultSelectedKey="all"
                label="Filtrar por:"
                options={INITIAL_OPTIONS}
                onChange={(e, { key }) => this.setState({ filtro: key })}
              />
              <span>&nbsp;&nbsp;</span>
              <CSVLink data={csvData} filename={`${team.name}-soccer-cup.csv`}>
                <PrimaryButton>Descargar listado</PrimaryButton>
              </CSVLink>
            </Stack>
          </Stack>
          <br />
        </>
      )
    );
  };

  renderTopContent = () => {
    const {
      selectionCount,
      puedeActivar,
      items,
      itemsLoaded,
      puedeCambiar,
      cambios,
    } = this.state;

    const { profile } = this.props;

    const jugadores = this.getActivos();
    const textoCambios = LIMITAR_CAMBIOS
      ? `&nbsp;Llevas ${cambios}/
    ${CAMBIOS_ETAPA_2} de los cambios permitidos`
      : '';

    const jugadoresActivos = jugadores.inscritos - jugadores.inactivos;

    const jugadoresCompletos = this.getCompletos();

    return (
      <div className="top">
        <h4>Listado de Jugadores</h4>
        <Shimmer isDataLoaded={itemsLoaded}>
          {items.length > 0 && (
            <>
              {this.renderFilter()}
              {this.renderRevision()}
              <Stack between middle className="teamsTopBar">
                <Text>
                  {jugadores.inscritos} jugadores inscritos:&nbsp;
                  {jugadoresActivos} activo(s) -&nbsp;
                  {jugadores.inactivos} inactivo(s).{textoCambios}
                </Text>

                {puedeCambiar ? (
                  <Stack middle>
                    <Text>Acciones&nbsp;&nbsp;</Text>
                    {!profile.isAdmin && (
                      <PrimaryButton
                        onClick={() => this.enviarARevision()}
                        disabled={jugadoresCompletos < JUGADORES_PARA_REVISION}>
                        Enviar a revisión
                      </PrimaryButton>
                    )}
                    &nbsp;
                    <PrimaryButton
                      onClick={() => this.activar()}
                      disabled={!puedeActivar}>
                      Activar {selectionCount}
                    </PrimaryButton>
                    &nbsp;
                    <DefaultButton onClick={() => this.activar(false)}>
                      Desactivar {selectionCount}
                    </DefaultButton>
                  </Stack>
                ) : null}
              </Stack>
            </>
          )}
        </Shimmer>
      </div>
    );
  };

  renderTable = () => {
    const { items, itemsLoaded, filtro } = this.state;
    const itemsFiltered =
      filtro === 'all'
        ? items
        : items.filter(item => {
            if (item.activo && filtro === 'activo') {
              return true;
            }
            if (!item.activo && filtro === 'inactivo') {
              return true;
            }
            return false;
          });
    return (
      <MarqueeSelection selection={this.selection}>
        <ShimmeredDetailsList
          items={itemsFiltered.map(item => ({
            ...item,
            key: item.identification,
            estado: item.activo ? (
              <span className="inscrito">Activo</span>
            ) : (
              <span className="noInscrito">Inactivo</span>
            ),
          }))}
          columns={this.columns}
          setKey="set"
          layoutMode={DetailsListLayoutMode.fixedColumns}
          selection={this.selection}
          selectionPreservedOnEmptyClick
          ariaLabelForSelectionColumn="Toggle selection"
          ariaLabelForSelectAllCheckbox="Toggle selection for all items"
          onItemInvoked={this.onItemInvoked}
          enableShimmer={!itemsLoaded}
        />
      </MarqueeSelection>
    );
  };

  renderRevision = () => {
    const { team, profile } = this.props;

    const { enRevision, aprobado, rechazado } = team;

    let estado = enRevision ? 'REVISION' : 'EDICION';
    estado = aprobado ? 'estado APROBADO' : estado;
    estado = rechazado ? 'estado RECHAZADO' : estado;

    const mostrar = enRevision || rechazado || aprobado || profile.isAdmin;

    return (
      mostrar && (
        <>
          <Stack
            column
            middle
            className={`teamsTopBar ${aprobado && 'fondoVerde'} ${rechazado &&
              'fondoRojo'}`}>
            <Text>Equipo en {estado}</Text>
            {profile.isAdmin && this.renderRevisionButtons()}
          </Stack>
          <br />
        </>
      )
    );
  };

  aprobar = async () => {
    const { team } = this.props;

    this.setState({ loading: true });
    await updateTeam({
      ...team,
      enRevision: false,
      aprobado: true,
      rechazado: false,
      aprobadoTime: new Date(),
    });
    this.setState({ loading: false });
    window.location = `/teams/${team.tid}`;
  };

  rechazar = async () => {
    const { team } = this.props;

    this.setState({ loading: true });
    await updateTeam({
      ...team,
      enRevision: false,
      aprobado: false,
      rechazado: true,
      rechazadoTime: new Date(),
    });
    this.setState({ loading: false });
    window.location = `/teams/${team.tid}`;
  };

  renderRevisionButtons = () => {
    const { team } = this.props;
    const { enRevision } = team;
    return (
      enRevision && (
        <>
          <br />
          <Stack>
            <PrimaryButton onClick={() => this.aprobar()}>
              Aprobar
            </PrimaryButton>
            &nbsp;
            <DefaultButton onClick={() => this.rechazar()}>
              Rechazar
            </DefaultButton>
          </Stack>
        </>
      )
    );
  };

  render() {
    return (
      <>
        {this.renderCommandHeader()}
        {this.renderContent()}
        {this.renderModal()}
        {this.renderModalTeam()}
        {this.renderRevisionModal()}
        {this.renderEliminarEquipo()}
      </>
    );
  }
}

Players.propTypes = {
  team: PropTypes.shape({ name: PropTypes.string }).isRequired,
  profile: profilePropType.isRequired,
};

Players.defaultProps = {};

export default profileOnly(Players);
