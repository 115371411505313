import React from 'react';
import { DefaultButton, Icon, Link } from 'office-ui-fabric-react/lib';
import { subYears } from 'date-fns';
import { formatShortDate, formatShortTime } from '../../../utils/dates';
import { traerTeamJugador } from '../../../utils/equipos';
import { validateForm, patterns } from '../../../utils/validator';

export default parent => {
  return [
    {
      key: 'column1',
      name: 'Nombre',
      fieldName: 'name',
      isResizable: true,
      onRender: item => {
        return (
          <span>
            {item.name.toUpperCase()}{' '}
            {item.capitan && <Icon iconName="PartyLeader" />}
          </span>
        );
      },
    },
    {
      key: 'column11',
      name: 'Apellido',
      fieldName: 'lastName',
      isResizable: true,
      onRender: item => {
        return item.lastName.toUpperCase();
      },
    },
    {
      key: 'column2',
      name: 'Cédula',
      fieldName: 'identification',
      isResizable: true,
    },
    {
      key: 'column3',
      name: 'Fecha de nacimiento',
      fieldName: 'birthday',
      isResizable: true,
      onRender: item => {
        return formatShortDate(item.birthday);
      },
    },
    {
      key: 'column4',
      name: 'Celular',
      fieldName: 'tel',
      isResizable: true,
    },
    {
      key: 'column6',
      name: 'Estado',
      fieldName: 'estado',
      isResizable: true,
    },
    {
      key: 'column7',
      name: 'e-mail',
      fieldName: 'email',
      isResizable: true,
    },
    {
      key: 'column8',
      name: 'Updated',
      fieldName: 'updatedOn',
      isResizable: true,
      onRender: item => {
        return (
          item.updatedOn && (
            <>
              {formatShortDate(item.updatedOn)}
              <br />
              {formatShortTime(item.updatedOn)}
            </>
          )
        );
      },
    },
    {
      key: 'column9',
      name: 'Archivos',
      onRender: item => {
        return (
          <>
            {item.upload1 && (
              <Link href={item.upload1} target="_blank">
                Archivo 1
              </Link>
            )}
            <br />
            {item.upload2 && (
              <Link href={item.upload2} target="_blank">
                Archivo 2
              </Link>
            )}
          </>
        );
      },
    },
    {
      key: 'column10',
      name: '',
      onRender: item => {
        return (
          <DefaultButton onClick={() => parent.onItemInvoked(item)}>
            Editar
          </DefaultButton>
        );
      },
    },
  ];
};

export const modelUser = {
  identification: '',
  name: '',
  lastName: '',
  birthday: subYears(new Date(), 18),
  tel: '',
  shirt: '',
  activo: true,
  email: '',
  capitan: false,
  updatedOn: '',
};

export const validarUsuario = async (user, nuevo, team) => {
  const schema = {
    identification: {
      type: 'string',
      empty: false,
      messages: { stringEmpty: 'Ingresa una cédula' },
    },
    name: {
      type: 'string',
      pattern: patterns.name,
      empty: false,
      messages: { stringEmpty: 'Ingresa un nombre' },
    },
    lastName: {
      type: 'string',
      pattern: patterns.name,
      empty: false,
      messages: { stringEmpty: 'Ingresa los apellidos' },
    },
    birthday: { type: 'date' },
    tel: {
      type: 'string',
      empty: false,
      length: 10,
      messages: {
        stringEmpty: 'Ingresa un celular',
        stringLength: 'Número de celular inválido',
      },
    },
    email: { type: 'email' },
  };

  const currentError = validateForm(user, schema);

  if (!currentError.identification && nuevo) {
    const teamUser = await traerTeamJugador(user);

    if (teamUser) {
      if (teamUser === team) {
        currentError.identification =
          'Esta cédula ya existe en el sistema, puedes modificar el registro existente con estos datos nuevos o cancelar la solicitud';
      } else {
        currentError.identification =
          'Esta cédula ya está registrada en otro equipo';
      }
    }
  }

  if (
    !currentError.identification &&
    user.oldId &&
    user.oldId !== user.identification
  ) {
    const teamUser = await traerTeamJugador(user);
    if (teamUser) {
      if (teamUser !== team) {
        currentError.identification =
          'Esta cédula ya está registrada en otro equipo';
      } else {
        currentError.identification = 'Esta cédula ya existe en el equipo';
      }
    }
  }

  return currentError;
};
