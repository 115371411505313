import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { DefaultButton } from 'office-ui-fabric-react/lib';
import { firebaseConnect, isLoaded } from 'react-redux-firebase';
import { PropTypes } from 'prop-types';
import { CSVLink } from 'react-csv';
import { profileStateToParams } from '../../utils/enhacers';
import { formatShortDate } from '../../utils/dates';
import { modelUser } from '../PlayersList/Forms/Jugadores';

const TeamsList = ({ teams, jugadores }) => {
  const keys = Object.keys(modelUser);

  if (!isLoaded(jugadores) || !isLoaded(teams) || !teams || !jugadores) {
    return null;
  }

  const csv = [['team', 'estado', ...keys]];

  const estado = ({ enRevision, aprobado, rechazado }) => {
    let color = enRevision ? 'EN REVISIÓN' : 'EN EDICIÓN';
    color = aprobado ? 'APROBADO' : color;
    color = rechazado ? 'RECHAZADO' : color;
    return color;
  };

  Object.keys(jugadores).forEach(teamId => {
    Object.values(jugadores[teamId]).forEach(value => {
      const values = keys.map(key => {
        return key === 'birthday' ? formatShortDate(value[key]) : value[key];
      });
      csv.push([teams[teamId].name, estado(teams[teamId]), ...values]);
    });
  });

  return (
    <CSVLink data={csv} filename="all-soccer-cup.csv">
      <DefaultButton>
        Descargar todos los equipos ({Object.keys(teams).length})
      </DefaultButton>
    </CSVLink>
  );
};

TeamsList.propTypes = {
  teams: PropTypes.shape({}),
  jugadores: PropTypes.shape({}),
};

TeamsList.defaultProps = {
  teams: undefined,
  jugadores: undefined,
};

export default compose(
  firebaseConnect(() => [{ path: 'teams' }, { path: 'jugadores' }]),
  connect(profileStateToParams),
)(TeamsList);
