import { storage } from 'firebase';

const subirFile = file => {
  const storageRef = storage().ref(
    `user-files-2023/${new Date().getTime()}-${file.name}`,
  );
  const task = storageRef.put(file);
  const promesa = new Promise(resolve => {
    task.on(
      'state_changed',
      () => {},
      () => {
        // TODO: Manejar el error
        resolve(null);
      },
      () => {
        task.snapshot.ref.getDownloadURL().then(imageUrl => {
          resolve(imageUrl);
        });
      },
    );
  });
  return promesa;
};

export const subirFiles = async (file1, file2) => {
  let objectoSubir = {};
  if (file1.files[0]) {
    const upload1 = await subirFile(file1.files[0]);
    objectoSubir = { upload1, upload1Time: new Date() };
  }
  if (file2.files[0]) {
    const upload2 = await subirFile(file2.files[0]);
    objectoSubir = { ...objectoSubir, upload2, upload2Time: new Date() };
  }

  return objectoSubir;
};

export default {};
